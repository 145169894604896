import { Box, Container, Grid, IconButton, Typography, useMediaQuery } from '@mui/material'
import React from 'react'
import { styled, useTheme } from '@mui/material/styles'
import { useSettings } from 'src/@core/hooks/useSettings'
import LoginBanner from 'src/assets/signup.png'
import WhiteBanner from 'src/assets/banner-white.png'
import Bg from 'src/assets/bg.png'

// import RegisterBanner from 'src/assets/images/signup-banner.png'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
const LeftWrapper = styled(Box)(({ theme }) => ({
  minHeight: '100vh',
  // padding: '24px',
  display: 'flex',
  backgroundColor: '',
}))

const LeftLoginContainer = styled(Box)(({ theme }) => ({
  borderRadius: 0,
  flex: '1',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: 'transparent',
  backgroundColor: '#0D0D0D',
  // backgroundImage: `url(${LoginBanner})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center'
}))

const LeftRegisterContainer = styled(Box)(({ theme }) => ({
  borderRadius: 0,
  flex: '1',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#0D0D0D',
  //backgroundImage: `url(${RegisterBanner})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center'
}))

const LoginIllustrationWrapper = styled(Box)(({ theme }) => ({
  marginTop: '125px',
  padding: '25px',
  maxWidth: '400px',
  alignItems: 'center'
}))

const RightWrapper = styled(Box)(({ theme }) => ({
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: theme.palette.mode === "light" ? '#FFFFFF' : '#000',
  backgroundImage: theme.palette.mode !== "light" ? `url(${Bg})` : '',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  //   margin: 'auto',
  // [theme.breakpoints.up('md')]: {
  //     maxWidth: 550
  // }
}))

const AuthLayout = ({ children }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const theme = useTheme()
  const { settings } = useSettings()
  const { skin } = settings
  const hidden = useMediaQuery(theme.breakpoints.down('md'))

  const handleGoBack = () => {
    window.location.href = 'https://main.dp3da536f13ml.amplifyapp.com/';
  };

  return (
    <>
      <Grid container>
        {!hidden ? (
          <Grid item xs={12} md={7} display='flex' justifyContent='center' spacing={5} width='100%' >
            <LeftWrapper style={{ overflow: 'hidden', height: '100vh' }}>
              {(location.pathname === '/register' || location.pathname === '/login') &&
                <Box sx={{ height: '100px', width: 'auto', marginTop: '80px' }}>
                  <IconButton onClick={handleGoBack}>
                    <ArrowBackIcon sx={{ color: '#AAC528' }} />
                  </IconButton>
                </Box>
              }
              {location.pathname === '/register' ?
                (
                  <LeftRegisterContainer >
                    <img src={theme.palette.mode === "light" ? WhiteBanner : LoginBanner} alt='LoginBanner' />
                  </LeftRegisterContainer>
                )
                : (
                  <LeftLoginContainer>
                    <img src={theme.palette.mode === "light" ? WhiteBanner : LoginBanner} alt='LoginBanner' />
                  </LeftLoginContainer>
                )}
            </LeftWrapper>
          </Grid>
        ) : (
          <></>
        )}
        <Grid item xs={12} md={5} sx={{ flex: 1, height: '100%' }}>
          <Box style={{ overflowY: 'scroll', height: '100vh' }}>
            <RightWrapper>
              {children}
            </RightWrapper>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default AuthLayout
