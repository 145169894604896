// ** Icon imports

import { ReactComponent as UsersIcon } from '../../assets/Users.svg';
import { ReactComponent as PromptLibraryIcon } from '../../assets/PromptLibrary.svg'
import { ReactComponent as FlexiblePriceIcon } from '../../assets/FlexiblePrice.svg'
import { ReactComponent as BillingHistoryIcon } from '../../assets/BillingHistory.svg'

const navigation = () => {

  

  return [
    {
      title: 'User Profile',
      icon: UsersIcon,
      path: '/userprofile'
    },
    {
      title: 'Prompts Library',
      icon: PromptLibraryIcon,
      path: '/promptlist'
    },
    {
      title: 'Flexible Price',
      icon: FlexiblePriceIcon,
      path: '/flexibleprice'
    },

    {
      title: 'Billing Hisotry',
      icon: BillingHistoryIcon,
      path: '/billinghistory'
    },
  ]
}

export default navigation
